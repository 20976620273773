<template>
  <div class="register-wrap">
    <div class="container">
      <div class="lable">{{ $t('login.account_registration') }}</div>
      <!-- 账号注册 -->
      <div class="detail">
        <a-form
          ref="formRef"
          :model="addFrom"
          @finish="handleSubmit"
          size="large"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 13 }"
          v-if="!successRegister"
        >
                 <!-- 单位 -->
            <a-form-item
              :label="$t('login.enterprise')"
              name="enterpriseId"
              v-if="useEnterprise == 1 || useUnitRegister == 1"
              @finish="handleSubmit"
            >
              <a-select
                v-model:value="addFrom['enterpriseId']"
                :options="enterpriseList"
                show-search
                :placeholder="$t('CM_Select')"
                size="large"
                :filterOption="filterOption"
              >
              <!-- 请选择 -->
              </a-select>
            </a-form-item>
            <a-form-item
              class="a-form-item-tip"
              v-if="useEnterprise == 1 || useUnitRegister == 1"
              :wrapper-col="{ span: 13, offset: 6 }"
            >
              <div class="tip">
                <span>{{ $t('login.enterprise_tip', 1) }}</span>
                <!-- 如果单位列表中找不到所在单位，请联系单位负责人 -->
                <span v-if="useUnitRegister == 1">
                  {{ $t('login.enterprise_tip', 2) }}
                  <!-- 先在平台进行 -->
                  <router-link to="/register/company">&nbsp;{{ $t('login.enterprise_registration') }}</router-link>
                  <!-- 单位注册 -->
                </span>
              </div>
            </a-form-item>
            <!-- 账号 -->
            <a-form-item :label="$t('login.账号')" name="account">
              <a-input
                v-model:value="addFrom.account"
                :placeholder="$t('login.account_regist')"
                size="large"
              />
              <!-- 请输入至少三个字符 -->
            </a-form-item>
            <!-- 密码 -->
            <a-form-item :label="$t('login.password')" name="password">
              <a-input-password
                v-model:value="addFrom.password"
                type="password"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 姓名 -->
            <a-form-item v-if="useName" :label="$t('login.name')" name="realName">
              <a-input
                v-model:value="addFrom.realName"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 手机号 -->
            <a-form-item :label="$t('login.mobile')" name="mobile">
              <a-input
                v-model:value="addFrom.mobile"
                :disabled="disabled"
                :placeholder="$t('LB_QA_PleaseEnter')"
                size="large"
              />
              <!-- 请输入 -->
            </a-form-item>
            <!-- 验证码 -->
            <a-form-item :label="$t('login.code')" name="code">
              <a-input-search
                v-model:value="addFrom.code"
                size="large"
                :placeholder="$t('LB_QA_PleaseEnter')"
                @search="getCode(addFrom.mobile)"
              >
              <!-- 请输入 -->
                <template #enterButton>
                  <a-button
                    type="primary"
                    style="background: #faad14; border-color: #faad14"
                    :disabled="disabled"
                    >{{ codeText }}</a-button
                  >
                </template>
              </a-input-search>
            </a-form-item>
        <template v-for="(item, index) in fromData.editFields" :key="index">
                  <template v-if="item.defaultField == 1">
                    <template
                    
                      v-if="item.key != 'userType'
                      "
                    >
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-if="item.type == 1 && item.key == 'realName'"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <div
                          v-if="platformConfig.platform == 26 || platformConfig.platform == 31"
                        >
                          <span class="ant-form-item-children">
                            <span class="ant-input-affix-wrapper ant-input-affix-wrapper-disabled">
                              <span class="ant-input-suffix" style="color: rgba(0, 0, 0, 0.25)">
                                <open-data
                                  type="userName"
                                  :openid="addFrom[item.key]"
                                  v-if="addFrom[item.key]"
                                />
                              </span>
                            </span>
                          </span>
                        </div>
                        <a-input
                          v-else
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${
                            item.name
                          }${$t('login.字以內', { total: 50 })}`"
                          allowClear
                          :maxlength="50"
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1 && item.key == 'account'"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-input
                          :disabled="platformConfig.platform == 26 || platformConfig.platform == 31"
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${
                            item.name
                          }${$t('login.字以內', { total: 50 })}`"
                          allowClear
                          :maxlength="50"
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1 && item.key == 'email'"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                          { validator: validatorEmail },
                        ]"
                      >
                        <a-input
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${item.name}`"
                          allowClear
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1 && item.key == 'mobile'"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                          { validator: validatorMobile },
                        ]"
                      >
                        <a-input
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${item.name}`"
                          allowClear
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1 && item.key == 'identityId'"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-input
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${
                            item.name
                          }${$t('login.字以內', { total: 50 })}`"
                          allowClear
                          :maxlength="50"
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1 && item.key == 'secretData'"
                        :rules="[
                          {
                            required: true,
                            message: $t('login.请选择') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-select
                          v-model:value="addFrom[item.key]"
                          allowClear
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        >
                          <a-select-option
                            v-for="(opt, optIndex) in secretList"
                            :value="opt.value"
                            :key="optIndex"
                          >
                            {{ $t(opt.i18nLabel) }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 1"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-input
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${
                            item.name
                          }${$t('login.字以內', { total: 50 })}`"
                          allowClear
                          :maxlength="50"
                        />
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 2"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            type:
                              item.key == 'city'
                                ? 'array'
                                : item.key == 'country'
                                ? 'string'
                                : 'number', // 城市为字符串数组、国家为字符串，其他为数值
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-cascader
                          :changeOnSelect="true"
                          v-if="item.key == 'city'"
                          allowClear
                          :field-names="fieldNames"
                          v-model:value="addFrom[item.key]"
                          :options="cityList"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        />
                        <a-tree-select
                          v-else-if="item.key == 'major'"
                          allowClear
                          v-model:value="addFrom[item.key]"
                          style="width: 100%"
                          :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                          :tree-data="majorList"
                          :placeholder="$t('login.请选择')"
                          treeNodeFilterProp="title"
                          :replaceFields="replaceFields"
                        ></a-tree-select>
                        <template v-else-if="item.key == 'department'">
                          <div
                            class="ant-form-item-control"
                            v-if="platformConfig.platform == 26 || platformConfig.platform == 31"
                          >
                            <span class="ant-form-item-children">
                              <span
                                class="ant-input-affix-wrapper ant-input-affix-wrapper-disabled"
                              >
                                <span class="ant-input-suffix" style="color: rgba(0, 0, 0, 0.25)">
                                  <OpenData
                                    type="departmentName"
                                    :openid="addFrom['departmentName']"
                                    v-if="addFrom['departmentName']"
                                  />
                                </span>
                              </span>
                            </span>
                          </div>
                          <a-tree-select
                            v-else
                            allowClear
                            v-model:value="addFrom[item.key]"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                            :tree-data="departmentList"
                            :placeholder="$t('login.请选择')"
                            treeNodeFilterProp="title"
                            :replaceFields="replaceFields"
                            :disabled="!!enterpriseId"
                          ></a-tree-select>
                        </template>
                        <a-select
                          v-else-if="item.key == 'country'"
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        >
                          <a-select-option
                            v-for="(opt, optIndex) in countryList"
                            :value="opt.countryName"
                            :key="optIndex"
                          >
                            {{ opt.countryName }}
                          </a-select-option>
                        </a-select>
                        <a-select
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-else-if="item.key == 'identityType'"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                          @change="identityTypeChange"
                        >
                          <a-select-option
                            v-for="opt in forOption[item.key]"
                            :value="opt.id"
                            :key="opt.id"
                          >
                            {{ opt.name }}
                          </a-select-option>
                        </a-select>
                        <a-select
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-else
                          v-model:value="addFrom[item.key]"
                          allowClear
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        >
                          <template v-if="item.key == 'position'">
                            <a-select-option
                              v-for="opt in positionList"
                              :key="opt.positionId"
                              :value="opt.positionId"
                            >
                              {{ opt.positionName }}
                            </a-select-option>
                          </template>
                          <template v-else>
                            <a-select-option
                              v-for="opt in forOption[item.key]"
                              :value="opt.id"
                              :key="opt.id"
                            >
                              {{ opt.name }}
                            </a-select-option>
                          </template>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                        :key="item.key"
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 6"
                        :rules="[
                          {
                            required: item.key == 'userType' ? true : item.required,
                            message: $t('login.请输入') + item.name,
                            type: item.key == 'userType' ? 'array' : 'string',
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-select
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-if="item.key == 'userType'"
                          mode="multiple"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                          :disabled="addFrom[item.key] == 8"
                        >
                          <a-select-option
                            v-for="opt in forOption[item.key]"
                            :value="opt.id"
                            :key="opt.id"
                            :disabled="opt.disabled"
                          >
                            {{ opt.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                        :label="item.name"
                        :required="item.required"
                        v-else-if="item.type == 3"
                      >
                        <a-select
                          v-if="item.key == 'label'"
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-model:value="addFrom[item.key]"
                          mode="multiple"
                          :maxTagPlaceholder="e => `+${e.length}`"
                          disabled
                        >
                          <a-select-option
                            v-for="opt in labelList"
                            :value="opt.labelId"
                            :key="opt.labelId"
                          >
                            {{ opt.labelName }}
                          </a-select-option>
                        </a-select>
                        <a-select
                          v-else
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                          :options="options"
                          :maxTagTextLength="5"
                          :maxTagCount="1"
                          mode="multiple"
                          :maxTagPlaceholder="e => `+${e.length}`"
                        ></a-select>
                      </a-form-item>
                      <a-form-item
                        :label="item.name"
                        :required="item.required"
                        v-else-if="item.type == 4"
                      >
                        <a-input-number
                          allowClear
                          v-model:value="addFrom[item.key]"
                          id="inputNumber"
                          :placeholder="`${$t('login.请输入')}${item.name}`"
                          :precision="0"
                        />
                      </a-form-item>
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                        v-else-if="item.type == 5"
                      >
                        <a-date-picker
                          :getCalendarContainer="triggerNode => triggerNode.parentNode"
                          valueFormat="YYYY-MM-DD"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          style="width: 100%"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        />
                      </a-form-item>
                    </template>
                  </template>
                  <template v-else>
                      <!-- 1:文本框 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-if="item.type == 1"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-input
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${
                            item.name
                          }${$t('login.字以內', { total: 50 })}`"
                          allowClear
                          :maxlength="50"
                        />
                      </a-form-item>
                      <!-- 2:下拉框 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 2"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请选择') + item.name,
                            trigger: 'change',
                            type: 'object',
                          },
                        ]"
                      >
                        <a-select
                          allowClear
                          labelInValue
                          :getPopupContainer="triggerNode => triggerNode.parentNode"
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                          :options="item.fieldOptions"
                        />
                      </a-form-item>
                      <!-- 4:数值 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 4"
                        :rules="[
                          {
                            required: item.required,
                            type: 'number',
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-input-number
                          allowClear
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${item.name}(${
                            item.len
                              ? $t('login.可輸入', { total: item.len })
                              : $t('login.請輸入整數')
                          })`"
                          :precision="item.len"
                          style="width: 100%"
                        />
                      </a-form-item>
                      <!-- 5:日期组件 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 5"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-date-picker
                          :getCalendarContainer="triggerNode => triggerNode.parentNode"
                          valueFormat="YYYY-MM-DD"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          style="width: 100%"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        />
                      </a-form-item>
                      <!-- 6:文本域 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-if="item.type == 6"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-textarea
                          v-model:value="addFrom[item.key]"
                          :placeholder="`${$t('login.请输入')}${item.name}`"
                          allowClear
                          :maxlength="500"
                          showCount
                        />
                      </a-form-item>
                      <!-- 7:时间组件 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 7"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                          },
                        ]"
                      >
                        <a-date-picker
                          :getCalendarContainer="triggerNode => triggerNode.parentNode"
                          valueFormat="YYYY-MM-DD HH:mm"
                          :show-time="{ format: 'HH:mm' }"
                          format="YYYY-MM-DD HH:mm"
                          v-model:value="addFrom[item.key]"
                          allowClear
                          style="width: 100%"
                          :placeholder="`${$t('login.请选择')}${item.name}`"
                        />
                      </a-form-item>
                      <!-- 8:树 -->
                      <a-form-item
                        :name="item.key"
                        :label="item.name"
                        v-else-if="item.type == 8"
                        :rules="[
                          {
                            required: item.required,
                            message: $t('login.请输入') + item.name,
                            trigger: 'change',
                            type: item.isCheck == 1 ? 'array' : 'object',
                          },
                        ]"
                      >
                        <a-tree-select
                          allowClear
                          labelInValue
                          v-model:value="addFrom[item.key]"
                          style="width: 100%"
                          :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                          :tree-data="item.fieldOptions"
                          :placeholder="$t('login.请选择')"
                          treeNodeFilterProp="title"
                          :multiple="item.isCheck == 1"
                          :treeCheckable="item.isCheck == 1"
                          :showCheckedStrategy="item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'"
                          :replaceFields="{
                            children: 'children',
                            title: 'optionValue',
                            key: 'optionKey',
                            value: 'optionKey',
                          }"
                          treeDefaultExpandAll
                        ></a-tree-select>
                      </a-form-item>
                  </template>
                </template>
            <a-form-item :wrapper-col="{ span: 14, offset: 9 }">
              <span class="btn"
                >
                <!-- 注册 -->
                 <a-button
                  type="primary"
                  block
                  html-type="submit"
                  :loading="submitting"
                  size="large"
                  >{{ $t('login.register_btn') }}</a-button
                ></span
              >
              <span class="btn"
                ><a-button
                  @click.prevent="toLogin"
                  block
                  size="large"
                  style="margin-left: 20px"
                  >{{ $t('login.to_login') }}</a-button
                ></span
              >
              <!-- 去登录 -->
            </a-form-item>
        </a-form>
        <!-- 恭喜您，注册成功！ -->
        <a-result
          status="success"
          :title="$t('login.register_success_title')"
          :sub-title="$t('login.register_success_tip')"
          v-else
        >
        <!-- 您的账号已经注册成功，快去登录平台试试吧。 -->
          <template #extra>
            <a-button key="console" type="primary" @click="toLogin"
              >{{ $t('login.to_login') }}</a-button
            >
            <!-- 去登录 -->
          </template>
        </a-result>
      </div>
    </div>
    <div class="copyright">
      <span v-if="studyCopyright" style="padding: 0 5px">{{
        studyCopyright
      }}</span>
      <a
        :href="`https://beian.miit.gov.cn${
          newWindow == 1 ? '?ddtab=true' : ''
        }`"
        :target="newWindow == 1 ? '_blank' : '_self'"
        v-if="icpNo"
      >
        {{ icpNo }}
      </a>
      <a
        v-if="ncNo"
        :target="newWindow == 1 ? '_blank' : '_self'"
        :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
          newWindow == 1 ? '&ddtab=true' : ''
        }`"
      >
        公网安备 {{ ncNo }}号
      </a>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, ref, watch, toRefs,onMounted,computed } from "vue";
import { useStore } from "vuex";
import {
  validatorMobile,
  validatorEmail,
  validatorPassword,
  validatorAccount,
} from "@/utils/formRules";
import {
  searchEnterprise,
  sendCode,
  registerIndividual,
  getArea,
  
  getMemberField,
  getLabelList,
  getPositionList,
  getCountryList,
  getDepartmentTree,
  getMajorTree
} from "@/api/user";
import { getCurrentInstance } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { appendJs } from "@/utils/tools.js";
import { currentHost } from "@/utils/request.js";

export default {
  setup() {
    const formRef = ref();
    const enterpriseList = ref([]);
    const { t: $t } = useI18n();
    const codeText = ref($t('login.get_code'));
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    let validateId = async (rule, value) => {
      console.log(value);
      if (!value || value == "") {
        return Promise.reject($t('login.enterprise_p'));
        // 请选择单位
      }
      return Promise.resolve();
    };
    const rules = {
        enterpriseId: [
          {
            required: true,
            validator: validateId,
            trigger: "change",
          },
        ],
        realName: [
          {
            required: true,
            message: $t('login.name_p'),
            // 请输入姓名
            trigger: "change",
          },
        ],
        account: [
          {
            required: true,
            message: $t('login.account_regist'),
            // 请输入至少三个字符
            trigger: "change",
          },
          {
            validator: validatorAccount,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: $t('login.password_p'),
            // 请输入密码
            trigger: "change",
          },
          {
            validator: validatorPassword,
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: $t('login.请输入手机号'),
            // 请输入手机号
            trigger: "change",
          },
          {
            validator: validatorMobile,
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: $t('login.code_p'),
            // 请输入验证码
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: $t('login.email_p'),
            // 请输入邮箱
            trigger: "change",
          },
          {
            validator: validatorEmail,
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: $t('login.area_p'),
            // 请选择区县
            trigger: "change",
            type: "array",
          },
        ],
      };
    const state = reactive({
      useEnterprise: 2,
      useUnitRegister:2,
      cityList: [],
      majorList: [],
      positionList: [],
      labelList: [],
      countryList: [],
      departmentList: [],
      forOption: {
        gender: [
          { name: '男', i18nName: 'avatar.男', id: 1 },
          { name: '女', i18nName: 'avatar.女', id: 2 },
        ],
        userType: [
          { name: '学员', i18nName: 'page.学员', id: 1 },
          { name: '讲师', i18nName: 'page.讲师', id: 2 },
          { name: '教练', i18nName: 'page.coach', id: 4 },
          { name: '管理员', i18nName: 'page.administrators', id: 8, disabled: true },
        ],
        identityType: [
          { name: '身份证', i18nName: 'page.IdCard', id: 1 },
          { name: '护照', i18nName: 'page.passport', id: 2 },
          { name: '台湾通行证', i18nName: 'page.TaiwanPass', id: 3 },
          { name: '港澳通行证', i18nName: 'page.HongKongMacaoPass', id: 4 },
        ],
      },
      userId:  0,
      enterpriseId: undefined,
      realName: "",
      account: "",
      password: "",
      mobile: "",
      code: "",
      email: "",
      address: "",
      city: [],
      fromData: {},
      addFrom: {},
      selectList: [],
      replaceFields: {
        children: 'list',
        title: 'name',
        key: 'id',
        value: 'id',
      },
      fieldNames: {
        label: 'name',
        value: 'name',
        children: 'list',
      },
      majorField: {
        label: 'name',
        value: 'id',
        children: 'list',
      },
      submitting: false,
      defPass: '',
      platformConfig:{platform:0}
    });
    const companyInfo = computed(() => store.getters['user/companyInfo']);
    // 获取验证码
    const disabled = ref(false);
    const successRegister = ref(false);
    
    // 处理表单提交
    const handleSubmit = () => {
      //state.submitting = true;
      console.log(state.addFrom);
      let submitData = JSON.parse(JSON.stringify(state.addFrom));
      submitData.position = submitData.position || 0;
      submitData.gender = submitData.gender || 3;
      submitData.identityType = submitData.identityType || 0;
      submitData.director = submitData.director || 0;
      submitData.major = submitData.major || 0;
      submitData.userType = 1;
      submitData.secretData = submitData.secretData ? parseInt(submitData.secretData) : 0;
      if (state.platformConfig.platform == 26 || state.platformConfig.platform == 31) {
        submitData.realName = state.addFrom.realName;
        submitData.account = state.addFrom.account;
        submitData.department = state.addFrom.department;
      }
      console.log(submitData);
      let submitData2 = {
        fields: {},
      };
      state.fromData.editFields.forEach(item => {
        if (submitData[item.key]) {
          if (item.defaultField == 1) {
            if (item.key == 'city') {
              let city2 = submitData.city;
              if (city2 && city2.length) {
                submitData2.province = city2[0];
                submitData2.city = city2[1] ? city2[1] : '';
                submitData2.district = city2[2] ? city2[2] : '';
              }
            } else {
              submitData2[item.key] = submitData[item.key];
            }
          } else {
            if (item.type == 2 || item.type == 8) {
              submitData2.fields[item.key] = JSON.stringify(submitData[item.key]);
            } else if (item.type == 4) {
              submitData2.fields[item.key] = String(submitData[item.key]);
            } else {
              submitData2.fields[item.key] = submitData[item.key];
            }
          }
        }
      });
      //赋值默认表单数据
      submitData2.enterpriseId = submitData.enterpriseId;
      submitData2.account = submitData.account;
      submitData2.password = proxy.$getRsaCode(submitData.password),
      submitData2.mobile = submitData.mobile;
      submitData2.realName = submitData.realName;
      submitData2.code = submitData.code;
      submitData2.entryDate = (submitData2.entryDate==""||submitData2.entryDate==undefined)?"": submitData2.entryDate.replaceAll('-','');
      submitData2.birthday =(submitData2.birthday==""||submitData2.birthday==undefined)?"": submitData2.birthday.replaceAll('-','');
      submitData2.number = submitData.number;
      submitData2.site = currentHost;
      submitData2.positionRank = submitData.positionRank;
      if (state.userId) submitData2.userId = Number(state.userId);
      console.log(submitData2);
      registerIndividual(submitData2)
        .then(res => {
          if (res.ret == 0) {
            proxy.$message.success(proxy.$t('操作成功'));
            closeWindow()
          } else {
            //proxy.$message.error(proxy.$t('common.操作失败') + res.msg);
          }
        })
        .finally(res => {
          state.submitting = false;
        });
    };

    // 获取人员添加表单
    const getAddForm = () => {
      let site = currentHost;
      //site = "gms-pc.51-learning.cn";
      getMemberField({site:site}).then(res => {
        let registerFormSetting = JSON.parse(res.registerFormSetting);
        state.platformConfig.platform = res.platform;
        res.editFields = res.editFields.filter(item => {
          if (item.defaultField == 2) {
            if (item.type == 2) {
              item.fieldOptions.map(opt => {
                opt.label = opt.optionValue;
                opt.value = opt.optionKey;
              });
            }
            if (item.type == 8 && item.isSelectChild == 1) {
              if (item.isCheck == 1) {
                isLeaf(item.fieldOptions, 'selectable');
              } else {
                isLeaf(item.fieldOptions, 'disabled');
              }
            }
          }
          let temp = registerFormSetting.filter(regist =>regist.lable == item.key);
          console.log(temp);
          if(temp.length>0){
            item.required = temp[0].required;
            return temp[0].isShow;
          }else{
            return false;
          }
        });
        console.log(res);
        state.fromData = res;
        console.log(state.fromData);
      });
    };

    const isLeaf = (arr, type) => {
      arr.forEach(item => {
        if (item.children?.length) {
          item.selectable = false;
          isLeaf(item.children, type);
        }
      });
    };
    
    const getCode = (searchValue) => {
      if (searchValue == "" || !/^1[3456789]\d{9}$/.test(searchValue)) {
        proxy.$message.error($t('login.mobile_err'));
        // 手机号不正确
        return false;
      }
      if(disabled.value){
        proxy.$message.error($t('请等待倒计时完成'));
        return false;
      }
      disabled.value = true;
      codeText.value = 60;
      let timer = setInterval(() => {
        codeText.value = parseInt(codeText.value) - 1;
        if (codeText.value == 0) {
          disabled.value = false;
          codeText.value = $t('login.get_code');
          // 获取验证码
          clearInterval(timer);
        }
      }, 1000);
      sendCode({
        mobile: searchValue,
        site: currentHost,
      }).then((res) => {
        if (res.ret == 0) {
          proxy.$message.success(res.msg); //发送验证码成功，请查收！
        }
      });
    };

    
    let validatorIdentityIdIndex = 0;
    const identityTypeChange = value => {
      validatorIdentityIdIndex = value;
    };

    const validatorIdentityId = () => {
      let value = state.addFrom.identityId;
      switch (validatorIdentityIdIndex) {
        case 1:
          if (value && !/(^\d{15}$)|(^\d{17}(x|X|\d)$)/.test(value)) {
            return Promise.reject(proxy.$t('page.IncorrectFormatOfIdCard'));
          }
          return Promise.resolve();
          break;
        case 2:
          if (
            value &&
            !/^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/.test(
              value,
            )
          ) {
            return Promise.reject(proxy.$t('page.IncorrectPassportFormat'));
          }
          return Promise.resolve();
        case 3:
          if (value && !/^\\d{8}|^[a-zA-Z0-9]{10}|^\\d{18}$/.test(value)) {
            return Promise.reject(proxy.$t('page.IncorrectFormatOfTaiwanPass'));
          }
          return Promise.resolve();
          break;
        case 4:
          if (value && !/^[HMhm]{1}([0-9]{8})$/.test(value)) {
            return Promise.reject(proxy.$t('page.IncorrectFormatOfHongKongAndMacaoPass'));
          }
          return Promise.resolve();
          break;
        default:
          return Promise.resolve();
          break;
      }
    };

    // 国家
    const initCountry = () => {
      getCountryList().then((res) => {
        if (res.ret === 0) {
          const data = res.data || [];
          state.countryList = data;
        }
      });
    };

    // 城市
    const initArea = () => {
      getArea().then((res) => {
        if (res.ret === 0) {
          const data = res.data || [];
          state.cityList = data;
        }
      });
    };
    const findCodeChrildren = (data, code) => {
      if (data && data.length) {
        for (let index = 0; index < data.length; index++) {
          const item = data[index];
          if (item.code == code) {
            state.areaTree = item.list;
          } else {
            findCodeChrildren(item.list, code);
          }
        }
      }
    };

    // 获取产品线
    const getMajorList = () => {
      getMajorTree().then(res => {
        state.majorList = res.data;
      });
    };

    // 获取岗位列表
    const getPosition = () => {
      getPositionList().then(res => {
        state.positionList = res.data;
      });
    };

    // 获取用户群列表
    const getLabel = () => {
      getLabelList().then(res => {
        state.labelList = res.data;
      });
    };
  
    const getEnterprise = () => {
      searchEnterprise({
        keyword: "",
        site: currentHost,
      }).then((res) => {
        if (res.ret == 0) {
          res.data.map((item) => {
            enterpriseList.value.push({
              value: item.id,
              label: item.name,
            });
          });
        }
      });
    };

    const getDepartmentList = () => {
      getDepartmentTree().then(res => {
        state.departmentList = res.data;
      });
    };
  
  watch(
    () => store.getters.platformConfig,
    (val) => {
      state.useEnterprise = val.useEnterprise;
      state.useUnitRegister = val.useUnitRegister;
      state.useName = val.useName;
      state.useAddress = val.useAddress;
      state.studyCopyright = val.studyCopyright;
      state.icpNo = val.icpNo;
      state.ncNo = val.ncNo;
      appendJs("Track", val.studyTrack);
      state.useArea = val.useArea;
    },
    { immediate: true, deep: true }
  );
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };


    const toLogin = () => {
      router.push({ path: `/login` });
    };

    onMounted(() => {
      getEnterprise();
      initArea();
      initCountry();
      getEnterprise();
      // getMajorList();
      // getPosition();
      // getLabel();
      //getDepartmentList();
      getAddForm();
    });

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      formRef,
      toLogin,
      companyInfo,
      handleSubmit,
      validatorEmail,
      validatorMobile,
      identityTypeChange,
      validatorIdentityId,
      successRegister,
      rules,
      codeText,
      getCode,
      enterpriseList,
      filterOption,
    };
  },
};
</script>
  
<style lang="less" scoped>
@import "../../assets/style/register.less";
.copyright {
  width: 960px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  color: #899299;
  ::v-deep(a) {
    color: #899299 !important;
  }
}
</style>